body {
  box-sizing: border-box;
  width: 100%;
}

.App {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.Root {
  box-sizing: border-box;
  width: 100%;
}

main {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 200px;
  margin-top: 50px;
  margin-left: 50px;
}

button {
  height: 45px;
  width: 100px;
  color: white;
  font-size: 15px;
  font-weight: bold;
  background-color: #F15D5D;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
}

#volver {
  float: right;
  margin-right: 100px;
}

.textarea-content {
  background-color: white;
  border: solid black 1px;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 20px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
}

pre {
  font-family: Calibri, Helvetica, sans-serif;
  font-size: 18px;
  white-space: pre-line;
}