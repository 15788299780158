.buttons-fullpage button {
    width: 30%;
    margin-right: 20px;
}

.buttons-line {
    box-sizing: border-box;
    width: 100%;
}

.buttons-line button {
    width: 30%;
    margin-right: 20px;
}

.title {
    width: 70%;
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
}

.reminder {
    font-style: italic;
    color: #DE1515;
    margin-top: 15px;
    margin-bottom: 15px;
}

.clarification {
    font-style: italic;
    color: gray;
    float: right;
}

.content {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #F5F5F5;
}

.content form label {
    width: 20%;
    padding-right: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
    box-sizing: border-box;
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 18px;
}

.doclink {
    margin-right: 16px;
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
}

.content form input {
    width: 100%;
    padding: 12px 20px;
    margin-top: 8px;
    margin-bottom: 16px;
    margin-right: 16px;
    box-sizing: border-box;
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 18px;
}

.content form select {
    padding: 12px 20px;
    margin-top: 8px;
    margin-bottom: 16px;
    margin-right: 16px;
    box-sizing: border-box;
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 18px;
}

textarea {
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 18px;
    box-sizing: border-box;
    width: 100%;
    height: 200px;
    padding: 12px 20px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 16px;
}

.content form p {
    font-style: italic;
    color: rgb(72, 71, 71);
}

.form-container {
    width: 100%;
    padding: 12px 20px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 16px;
    box-sizing: border-box;
    font-size: 18px;
    border: solid black 1px;
}

.form-container textarea {
    height: 200px;
}

.content form .buildable-list .form-container p {
    float: none;
    font-style: normal;
    font-size: 16px;
    margin-left: 32px;
}

.content form .form-container input[type='text'] {
    width: 100%;
}

.content form .form-container input[type='file'] {
    width:fit-content;
    font-size: 16px;
}

.content form .form-container button {
    width: 200px;
    margin-left: 0px;
    margin-bottom: 8px;
}

.buildable-list {
    box-sizing: border-box;
    width: 100%;
}
.content form .buildable-list p {
    display: inline;
    float: inline-start;
    margin-right: 10px;
}

.content form .buildable-list input {
    width: 100%;
    height: fit-content;
}

.actuacion {
    display: block;
}

.actuacion-data {
    display: inline-block;
    width: 95%;
    padding: 12px 20px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 16px;
    box-sizing: border-box;
    font-size: 18px;
    border: solid black 1px;
    background-color: white;
}

.actuacion-data textarea {
    height: 200px;
}

.content form button {
    margin-left: 10px;
}

#guardar {
    width: 30%;
}

#form-ending {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
}

.field {
    display: block;
}

.docform {
    display: block;
    width: 100%;
    margin-bottom: 25px;
}

.docform button {
    width: fit-content;
}

.docstatus {
    margin-top: 15px;
    margin-bottom: 15px;
}