header {
    height: 127px;
    align-self: center;
    box-sizing: border-box;
    width: 100%;
}

header .logo {
    left: 37px;
    object-fit: cover;
    position: absolute;
    top: 5px;
    width: 250px;
}

header h1 {
    color: #de1515;
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 32px;
    font-weight: bold;
    left: 470px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 8px;
}

header h2 {
    color: grey;
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: bold;
    left: 50px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 65px;
}
  
header #logo {
    display: inline;
    position: absolute;
    top: 15px;
    left: 10%;
}

header #logo h1 {
    color: #de1515;
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0px;
}

header #logo img {
    display: block;
    position: absolute;
    left: 650px;
    top: 30px;
}

header .logged-user {
    right: 50px;
    position: absolute;
    top: 30px;
    width: 289px;
}

header .user-blob {
    height: 87px;
    width: 170px;
    position: absolute;
    top: 3px;
    right: 105px;
    
}

header #salir {
    right: 0px;
    position: absolute;
    top: 18px;
    width: 85px;
}

header #inicio {
    right: 300px;
    position: absolute;
    top: 18px;
    width: 85px;
}

header hr {
    position: relative;
    top: 140px;
    height: 1px;
    background-color: grey;
    width: 100%;
}