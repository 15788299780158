.user input[type=checkbox] {
    display: inline;
    width: auto;
}

.checkline {
    display: block;
}

#responsables {
    width: fit-content;
}