.title {
    width: 70%;
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
}

.reminder {
    font-style: italic;
    color: #DE1515;
}

.clarification {
    font-style: italic;
    color: gray;
    float: right;
}

.content {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #F5F5F5;
}

.content form label {
    width: 20%;
    padding-right: 12px;
    margin-top: 12px;
    box-sizing: border-box;
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 18px;
}

.content form input {
    width: 100%;
    padding: 12px 20px;
    margin-top: 8px;
    margin-bottom: 16px;
    margin-right: 16px;
    box-sizing: border-box;
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 18px;
}

.content form select {
    padding: 12px 20px;
    margin-top: 8px;
    margin-bottom: 16px;
    margin-right: 16px;
    box-sizing: border-box;
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 18px;
}

textarea {
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 18px;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    padding: 12px 20px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 16px;
}

.content form p {
    font-style: italic;
    color: rgb(72, 71, 71);
}

.content form .buildable-list input {
    width: 15%;
}

.content form .buildable-list p {
    display: inline;
    float: inline-start;
    margin-right: 10px;
}

.form-container {
    width: 100%;
    padding: 12px 20px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 16px;
    box-sizing: border-box;
    font-size: 18px;
    border: solid black 1px;
}

.content form .buildable-list .form-container p {
    float: none;
    font-style: normal;
    font-size: 16px;
    margin-left: 32px;
}

.involucrada {
    display: block;
}

.involucrada-data {
    display: inline-block;
    width: 95%;
    padding: 12px 20px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 16px;
    box-sizing: border-box;
    font-size: 18px;
    border: solid black 1px;
    background-color: white;
}

.profesional {
    display: block;
}

.profesional-data {
    display: inline-block;
    width: 95%;
    padding: 12px 20px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 16px;
    box-sizing: border-box;
    font-size: 18px;
    border: solid black 1px;
    background-color: white;
}

.content form button {
    margin-left: 10px;
}

#guardar {
    width: 30%;
}

#form-ending {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
}