.login {
    position: relative;
    top: 50px;
    text-align: center;
}
.login h2 {
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 23px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: normal;
}

.login button {
    height: 45px;
    right: 0px;
    position: relative;
    top: 5px;
    width: 30%;
    font-size: 15px;
    color: white;
    font-weight: bold;
    background-color: #de1515;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
}