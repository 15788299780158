h2 {
    font-weight: normal;
}
.buttons {
    margin-top: 30px;
}
.buttons button {
    width: 30%;
    margin: 0px 20px 0px 0px;
}

#numexpedientes {
    font-weight: bold;
    font-size: larger;
}