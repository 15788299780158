#search-title {
    display: none;
}

#volver {
    position: relative;
    width: 200px;
    top: 10px;
}

#limpiar {
    position: relative;
    width: 200px;
    top: -60px;
    float: right;
}

#search-box input[type=text] {
    width: 40%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 18px;
}

#search-box select {
    padding: 12px 20px;
    margin: 8px;
    box-sizing: border-box;
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 16px;
}

.search-results {
    margin-top: 20px;
    background-color: #F5F5F5;
    padding: 20px;
    width: 90%;
}

.search-results .results-title {
    display: block;
    margin-top: 0px;
    font-weight: normal;
}

.results {
    display: inline-block;
    vertical-align: top;
    width: 30%;
}

.searchfilters {
    display: block;
}

.filter {
    display: inline-block;
    vertical-align: top;
    width: 35%;
}

#estado-div {
    width: 35%;
}

#filtro-anyo {
    visibility: hidden;
    padding: 12px 10px;
    margin: 8px 0;
    box-sizing: border-box;
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 18px;
}

#filtro-plataforma {
    visibility: hidden;
}

.rapida-btn {
    width: fit-content;
}

#todos-btn {
    width: fit-content;
    margin: 10px;
}