.boton-barra {
    margin: 8px;
}

.table-container {
    display: table;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
}
  
.table-row {
    display: table-row;
}
  
.table-cell {
    width: 30%;
    display: table-cell;
    padding: 8px;
    border-bottom: 2px solid white;}
  
.header {
    background-color: lightgray;
    font-weight: bold;
}

.icon-button {
    width: 100px;
    height: 50px;
}
      
.icon-button img {
    width: 25px;
    height: 25px;
    color: white;
}

input[type=text] {
    width: 40%;
    padding: 8px 20px;
    margin-right: 8px;
    box-sizing: border-box;
    font-family: Calibri, Helvetica, sans-serif;
    font-size: 18px;
}

form button {
    margin-right: 8px;
}